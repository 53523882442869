import { PortalIcons, WelcomePage } from '@eagle/react-common';
import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Welcome: FC = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'track']);

  const renderLogo = (smallScreen: boolean): JSX.Element => <PortalIcons.Tracking
    sx={{
      alignSelf: 'center',
      color: theme.palette.primary.main,
      height: smallScreen ? 60 : 100,
      width: smallScreen ? 60 : 100,
    }}
  />;

  const renderTitle = (smallScreen: boolean): JSX.Element => <>
    <Typography fontWeight={400} variant={smallScreen ? 'h5' : 'h4'}>{t('common:page.welcome.title')}</Typography>
    <Typography fontWeight={600} sx={{ marginTop: 1 }} variant={smallScreen ? 'h5' : 'h4'}>{t('common:common.labels.portal-name', { display: t('common:common.labels.track') })}</Typography>
  </>;

  const renderText = (smallScreen: boolean): JSX.Element => <>
    <Typography variant={smallScreen ? 'body2' : 'body1'}>{t('common:page.welcome.hint.no-permissions')}</Typography>
    <Typography variant={smallScreen ? 'body2' : 'body1'}>{t('common:page.welcome.hint.contact-admin')}</Typography>
  </>;

  return <WelcomePage portal="track" renderLogo={renderLogo} renderText={renderText} renderTitle={renderTitle} title={t('common:common.labels.track')} />;
};
