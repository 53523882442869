import { AppWrapper } from '@eagle/react-common';
import { createRoot } from 'react-dom/client';
import Site from './site';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(
  <AppWrapper>
    <Site />
  </AppWrapper>
);
