import { DeviceId } from '@eagle/core-data-types';
import {
  AuthStatus,
  CacheDataTypes,
  CacheProvider,
  defineFetchAllCacheFor,
  defineFetchOneCacheFor,
  MiddleSpinner,
  PortalResources,
  SettingsProvider,
  SidebarPinContextProvider,
  useAuth,
  useClarity,
  useFlagIdentity,
  useHotjar,
  useResourceLoader,
  useSentryIdentity
} from '@eagle/react-common';
import { FC } from 'react';
import Body from './body';
import { Landing } from './pages/landing';
import './site.css';

const fetchAllCaches = {
  [CacheDataTypes.ACCOUNT]: defineFetchAllCacheFor('/api/v1/account?sort=display'),
  [CacheDataTypes.DEVICE_TYPE]: defineFetchAllCacheFor('/api/v1/device-type?sort=display'),
  [CacheDataTypes.FEATURE]: defineFetchAllCacheFor('/api/v1/feature'),
  [CacheDataTypes.FEATURE_TYPE]: defineFetchAllCacheFor('/api/v1/feature-type'),
  [CacheDataTypes.GATEWAY]: defineFetchAllCacheFor('/api/v1/gateway?sort=display'),
  [CacheDataTypes.GEOFENCE_GROUP]: defineFetchAllCacheFor('/api/v1/geofence-group?sort=display'),
  [CacheDataTypes.GEOFENCE_TYPE]: defineFetchAllCacheFor('/api/v1/geofence-type?sort=display'),
  [CacheDataTypes.GROUP]: defineFetchAllCacheFor('/api/v1/group?sort=display'),
  [CacheDataTypes.INSTALLATION_TEMPLATE]: defineFetchAllCacheFor('/api/v1/installation-template?sort=display'),
  [CacheDataTypes.MY_FEATURE]: defineFetchAllCacheFor('/api/v1/my/features'),
  [CacheDataTypes.PERSON_TYPE]: defineFetchAllCacheFor('/api/v1/person-type?sort=display'),
  [CacheDataTypes.POINT_OF_INTEREST_SET]: defineFetchAllCacheFor('/api/v1/my/point-of-interest-set?sort=display'),
  [CacheDataTypes.POINT_OF_INTEREST_TYPE]: defineFetchAllCacheFor('/api/v1/point-of-interest-type?sort=display'),
  [CacheDataTypes.SHARED_THING_TYPE]: defineFetchAllCacheFor('/api/v1/shared-thing-type?sort=display'),
  [CacheDataTypes.THING_TYPE]: defineFetchAllCacheFor('/api/v1/thing-type?sort=display'),
  [CacheDataTypes.USER_GROUP]: defineFetchAllCacheFor('/api/v2/user-group?sort=display'),
};

const fetchOneCaches = {
  [CacheDataTypes.ALERT]: defineFetchOneCacheFor('/api/v1/alert'),
  [CacheDataTypes.BRAND]: defineFetchOneCacheFor('/api/v1/brand'),
  [CacheDataTypes.COUNTRY]: defineFetchOneCacheFor('/api/v1/country'),
  [CacheDataTypes.DEVICE]: defineFetchOneCacheFor('/api/v1/device', (deviceId: DeviceId) => `${deviceId.gatewayId}/${deviceId.deviceId}`),
  [CacheDataTypes.LIFECYCLE_TEMPLATE]: defineFetchOneCacheFor('/api/v1/my/life-cycle-template'),
  [CacheDataTypes.MODEL]: defineFetchOneCacheFor('/api/v1/model'),
  [CacheDataTypes.MY_SUBSCRIPTION]: defineFetchOneCacheFor('/api/v1/my/subscription'),
  [CacheDataTypes.MY_USER]: defineFetchOneCacheFor('/api/v1/my/user'),
  [CacheDataTypes.PERSON]: defineFetchOneCacheFor('/api/v1/person'),
  [CacheDataTypes.SHARED_THING]: defineFetchOneCacheFor('/api/v1/shared-thing'),
  [CacheDataTypes.THING]: defineFetchOneCacheFor('/api/v1/thing'),
  [CacheDataTypes.INSTALLATION]: defineFetchOneCacheFor('/api/v1/installation'),
  [CacheDataTypes.USER]: defineFetchOneCacheFor('/api/v1/user'),
  [CacheDataTypes.VARIANT]: defineFetchOneCacheFor('/api/v1/variant'),
};

const Site: FC = () => {
  const { state } = useAuth();

  useHotjar();
  useClarity();
  useFlagIdentity();
  useSentryIdentity();
  useResourceLoader(PortalResources.Track);

  if (!state || state.status === AuthStatus.LOADING) return <MiddleSpinner />;
  if (state.status !== AuthStatus.AUTHENTICATED) return <Landing />;

  return (
    <SettingsProvider>
      <CacheProvider authState={state} fetchAllCaches={fetchAllCaches} fetchOneCaches={fetchOneCaches}>
        <SidebarPinContextProvider>
          <Body />
        </SidebarPinContextProvider>
      </CacheProvider>
    </SettingsProvider>
  );
};

export default Site;
