import { ProfilePageComponent } from '@eagle/react-common';
import { lazy } from 'react';

export const AlertDetailPage = lazy(() => import('./alert'));
export const AlertListPage = lazy(() => import('./alert-list'));
export const CameraContentPage = lazy(() => import('./camera-content/camera-content'));
export const DashboardPage = lazy(() => import('./dashboard'));
export const DashboardsListPage = lazy(() => import('./dashboards-list'));
export const EventHistoryPage = lazy(() => import('./history'));
export const OverviewPage = lazy(() => import('./overview'));
export const PersonDetailPage = lazy(() => import('./person/person-detail'));
export const PersonListPage = lazy(() => import('./person-list'));
export const ProfilePage = ProfilePageComponent;
export const ReportsListPage = lazy(() => import('./reports-list'));
export const ReportsPage = lazy(() => import('./reports-page'));
export const DashboardDetailPage = lazy(() => import('./dashboard-detail'));
export const SubscriptionCreatePage = lazy(() => import('./create-subscription'));
export const SubscriptionDetailPage = lazy(() => import('./subscription'));
export const ThingDetailPage = lazy(() => import('./thing/thing-detail'));
export const ThingListPage = lazy(() => import('./thing-list'));
export const ThingMapPage = lazy(() => import('./thing-map'));
