import { LandingPage, useTitle } from '@eagle/react-common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Landing: FC = () => {
  const { t } = useTranslation(['common']);

  useTitle(t('common:common.labels.track'));

  return (
    <LandingPage portalName='track' />
  );
};
